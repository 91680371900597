import { render, staticRenderFns } from "./MatrixResultsViewer.vue?vue&type=template&id=3c7f5438&scoped=true&"
import script from "./MatrixResultsViewer.vue?vue&type=script&lang=ts&"
export * from "./MatrixResultsViewer.vue?vue&type=script&lang=ts&"
import style0 from "./MatrixResultsViewer.vue?vue&type=style&index=0&id=3c7f5438&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c7f5438",
  null
  
)

export default component.exports