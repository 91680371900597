










import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("change", { searchTerm: this.searchTerm });
    },
    clearAllSearchTerms() {
      this.searchTerm = "";
      this.$emit("change", { searchTerm: this.searchTerm });
    },
  },
});
