








import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import { DiscogsSearchResults } from '../types'

import ArtistAndSongForm from '@/components/ArtistAndSongForm.vue'
import MatrixResultsViewer from '@/components/MatrixResultsViewer.vue'

export default Vue.extend({
  name: 'App',
  components: {
    ArtistAndSongForm,
    MatrixResultsViewer
  },
  data () {
    return {
      searchTerm: '',
      results: {} as DiscogsSearchResults
    }
  }
})
