











import Vue from 'vue'

export default Vue.extend({
  name: 'LabelForm',
  data () {
    return {
      label: ''
    }
  },
  mounted() {
    (this.$refs.input as any).focus()
  },
  methods: {
    clearAllSearchTerms () {
      this.label = ''
      this.$emit('change', {})
    },
    getMatrixTrackList () {
      this.$emit('change', { searchTerm: this.label })
    }
  }
})
