











import Vue from 'vue'
import { DiscogsAPI } from '@/api'
import stringSimilarity from 'string-similarity'

// eslint-disable-next-line no-unused-vars
import { DiscogsSearchResult, DiscogsRelease, Track } from '../types'

export default Vue.extend({
  props: {
    searchTerm: {
      type: String,
      required: false,
      default: ''
    },
    result: {
      type: Object as () => DiscogsSearchResult,
      required: false,
      default: () => ({} as DiscogsSearchResult)
    }
  },
  data () {
    return {
      track: {} as Track,
      artist: '',
      matrix: '',
      label: '',
      catno: '',
      status: ''
    }
  },
  watch: {
    result: {
      handler(result: DiscogsSearchResult): void {
        this.clearData()

        if (result.id && result.id > 0) {
          DiscogsAPI
            .getRelease(result.id)
            .then((response: DiscogsRelease | undefined): void => {
              if (response) {
                const bestTrackMatch = response.tracklist.find(track => {
                  const artistAndSong = this.searchTerm.split(' - ')
                  if (artistAndSong.length === 2) {
                    const similarity = stringSimilarity.compareTwoStrings(track.title, artistAndSong[1])
                    return similarity > 0.3
                  } else {
                    const similarity = stringSimilarity.compareTwoStrings(track.title, this.searchTerm)
                    return similarity > 0.1
                  }
                })

                if (bestTrackMatch) {
                  this.track = bestTrackMatch
                  this.artist = response.artists_sort
                  this.label = result.label.join(' ')
                  this.catno = result.catno
                  const maybeMatrix = this.getMatrix(response, bestTrackMatch)
                  this.matrix = maybeMatrix || 'Not listed'

                  if (maybeMatrix) {
                    this.status = `Matrix found for ${this.track.title} ${this.artist}`
                    { (this.$refs as any).matrix.focus() }
                  } else {
                    this.status = `No matrix found for ${this.track.title} ${this.artist}`
                  }
                }
              }
            })
        }
      },
      deep: true
    }
  },
  methods: {
    clearData(): void {
      this.track = {} as Track
      this.artist = '',
      this.matrix = '',
      this.status = '',
      this.label = '',
      this.catno = ''
    },
    getMatrix(release: DiscogsRelease, track: Track): string | undefined {
      const matrixes = release.identifiers

      const regex = new RegExp('(Side){0,1}\\s*' + track.position + '\\s*(Runout|Side){0,1}')

      const maybeMatrix = matrixes.find(m => m.description ? m.description.match(regex) : false)
      
      if (maybeMatrix) {
        return maybeMatrix.value
      } else {
        if (matrixes.length >= 2 && (track.position.includes('a') || track.position.includes('A'))) {
          return matrixes[0].value
        } else if (matrixes.length >= 2 && (track.position.includes('b') || track.position.includes('B'))) {
          return matrixes[1].value
        } else {
          return undefined
        }
      }
    }
  }
})
