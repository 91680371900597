








import Vue from 'vue'
import { DiscogsAPI } from '@/api'
import ArtistForm from '@/components/ArtistForm.vue'
// eslint-disable-next-line no-unused-vars
import { DiscogsSearchResults } from '@/types'
import ArtistSongList from '@/components/ArtistSongList.vue'

export default Vue.extend({
  components: {
    ArtistForm,
    ArtistSongList
  },
  data () {
    return {
      searchTerm: '',
      results: {} as DiscogsSearchResults
    }
  },
  watch: {
    searchTerm(newVal: string, oldVal: string) {
      this.results = {}

      if (newVal && newVal !== oldVal) {
        DiscogsAPI
          .searchForArtist(newVal)
          .then((response: DiscogsSearchResults) => {
            this.results = response
          })
      }
    }
  },
  methods: {
    changepage(page: number) {
      this.results = {}

      DiscogsAPI
        .searchForArtist(this.searchTerm, page)
        .then((response: DiscogsSearchResults) => {
          this.results = response
        })
    }
  }
})
