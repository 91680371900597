








import Vue from 'vue'
import { DiscogsAPI } from '@/api'
import LabelForm from '@/components/LabelForm.vue'
// eslint-disable-next-line no-unused-vars
import { DiscogsSearchResults } from '@/types'
import LabelSongList from '@/components/LabelSongList.vue'

export default Vue.extend({
  components: {
    LabelForm,
    LabelSongList
  },
  data () {
    return {
      searchTerm: '',
      results: {} as DiscogsSearchResults
    }
  },
  watch: {
    searchTerm(newVal: string, oldVal: string) {
      this.results = {}

      if (newVal && newVal !== oldVal) {
        DiscogsAPI
          .searchForLabel(newVal)
          .then((response: DiscogsSearchResults) => {
            this.results = response
          })
      }
    }
  },
  methods: {
    changepage(page: number) {
      this.results = {}

      DiscogsAPI
        .searchForLabel(this.searchTerm, page)
        .then((response: DiscogsSearchResults) => {
          this.results = response
        })
    }
  }
})
