










import Vue from "vue";
import { YoutubeAPI } from "@/api";
// eslint-disable-next-line no-unused-vars
import { YoutubeResponseItem, PlaylistResponseItem } from "@/types";
import { getListOfYoutubeUrls, getListOfYoutubeUrlsExtraInfo } from "@/helpers";
import PlaylistSearchForm from "@/components/PlaylistSearchForm.vue";

export default Vue.extend({
  components: { PlaylistSearchForm },
  data() {
    return {
      searchTerm: "",
      results: [] as string[],
      resultsExtraInfo: [] as string[],
      status: "",
      showDownloadButton: false
    };
  },
  watch: {
    searchTerm(newVal: string, oldVal: string): void {
      this.results = []
      this.showDownloadButton = false;

      if (newVal && newVal !== oldVal) {
        this.status = "Searching for " + newVal

        this.getPlaylistItems(newVal).then((response) => {
          if (response) {
            this.results = getListOfYoutubeUrls(response);
            this.resultsExtraInfo = getListOfYoutubeUrlsExtraInfo(response);
            this.prepareDownload();
            this.status = "";
          }
        })
      }
    },
  },
  methods: {
    prepareDownload (): void {
        this.showDownloadButton = true;
        this.$nextTick(() => {
            var downloadButton = document.getElementById("download");
            if (downloadButton) {
                downloadButton.focus();
            }
        })
    },
    async getPlaylistItems (paylistId: string): Promise<Array<PlaylistResponseItem>> {
      var results = [] as Array<PlaylistResponseItem>
      var currentPage = 1
      var nextPageToken = await YoutubeAPI.getPlaylistItems(paylistId).then(response => { results.push(...response.items); return response.nextPageToken; })
      
      while (nextPageToken && currentPage < 20 /* 1000 results */) {
        nextPageToken = await YoutubeAPI.getPlaylistItems(paylistId, nextPageToken).then(response => { results.push(...response.items); return response.nextPageToken; })
        currentPage++
      }

      return results
    },
    downloadTextFile (): void {
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.results.map(t => t).join('\r\n')))
      element.setAttribute('download', 'Youtube Playlist.txt')

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },
    downloadTextFileWithExtraInfo (): void {
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.resultsExtraInfo.map(t => t).join('\r\n')))
      element.setAttribute('download', 'Youtube Playlist with titles.txt')

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    }
  },
});
