

























import Vue from 'vue'
import BestMatch from './BestMatch.vue'
// eslint-disable-next-line no-unused-vars
import { DiscogsSearchResults, DiscogsSearchResult, URLString } from '../types'
import stringSimilarity from 'string-similarity'

type ResultListItem = {
  title: string;
  releaseId: number;
  url: URLString;
  result: DiscogsSearchResult;
  similarity?: number;
}

export default Vue.extend({
  name: 'MatrixResultsViewer',
  components: {
    BestMatch
  },
  props: {
    searchTerm: {
      type: String,
      required: false,
      default: ''
    },
    results: {
      type: Object as () => DiscogsSearchResults,
      required: false,
      default: () => ({} as DiscogsSearchResults)
    }
  },
  data () {
    return {
      resultsList: [] as Array<ResultListItem>,
      bestMatch: {} as DiscogsSearchResult,
      resultsStatus: ''
    }
  },
  watch: {
    searchTerm: {
      handler(): void {
        this.resultsStatus = ''
        this.$nextTick()
      }
    },
    results: {
      handler(val: DiscogsSearchResults): void {
        this.clearData()

        this.resultsList = this.generateResultsList(val)
        const maybeBestMatch = (this.resultsList as Array<ResultListItem>).find(r => {
          if (r.similarity && r.result && r.result.format && r.result.resource_url) {
            return r.result.format.includes('Shellac') && r.similarity > 0.3 && r.result.resource_url.includes('release')
          } else {
            return false
          }
        })

        if (maybeBestMatch) {
          this.bestMatch = maybeBestMatch.result
        }

        this.resultsStatus = `${maybeBestMatch ? 'Best match found. ' : ''}Total ${ this.resultsList ? this.resultsList.length : 0 } results with matrix, for ${ this.searchTerm }`
      },
      deep: true
    }
  },
  methods: {
    clearData () {
      this.resultsList = [] as Array<ResultListItem>
      this.bestMatch = {} as DiscogsSearchResult
      this.resultsStatus = ''
    },
    generateResultsList (results: DiscogsSearchResults): Array<ResultListItem> {
      const list = [] as Array<ResultListItem>

      const tracks = results.results

      if (tracks) {
        tracks.forEach((result: DiscogsSearchResult) => {
          list.push({
            title: result.title,
            releaseId: result.id,
            url: `https://www.discogs.com${result.uri}`,
            similarity: this.searchTerm ? stringSimilarity.compareTwoStrings(result.title, this.searchTerm) : undefined,
            result
          })
        });
      }

      return list
    }
  }
})
