











import Vue from 'vue'
import { DiscogsAPI } from '@/api'
// eslint-disable-next-line no-unused-vars
import { DiscogsSearchResults } from '@/types'

export default Vue.extend({
  name: 'ArtistAndSongForm',
  data () {
    return {
      artistAndSong: '',
      artist: '',
      song: '',
    }
  },
  mounted() {
    let firstFocus = this.$refs.input as any
    firstFocus.focus()
  },
  methods: {
    clearAllSearchTerms() {
      this.artistAndSong = ''
      this.artist = ''
      this.song = ''
      this.$emit('change', {})
    },
    trySplitSearchTerm() {
      if (this.artistAndSong) {
        let maybeArtistAndSong = this.artistAndSong.split(' - ')
        if (maybeArtistAndSong.length === 2) {
          this.artist = maybeArtistAndSong[0].trim()
          this.song = maybeArtistAndSong[1].trim()
        } else {
          maybeArtistAndSong = this.artistAndSong.split(' -')
          if (maybeArtistAndSong.length === 2) {
            this.artist = maybeArtistAndSong[0].trim()
            this.song = maybeArtistAndSong[1].trim()
          } else {
            maybeArtistAndSong = this.artistAndSong.split('- ')
            if (maybeArtistAndSong.length === 2) {
              this.artist = maybeArtistAndSong[0].trim()
              this.song = maybeArtistAndSong[1].trim()
            }
          }
        }
      }
    },
    async getMatrix () {
      this.trySplitSearchTerm()

      if (this.artist && this.song) {
        DiscogsAPI
        .searchForSpecific(this.artist, this.song)
        .then((response: DiscogsSearchResults) => {
          this.$emit('change', { searchTerm: `${this.artist} - ${this.song}`, results: response })
          return response
        }).then(response => {
          if (response && response.results && response.results.length === 0) {
            // if specific search doesn't find anything, try a more generic search
            DiscogsAPI
              .searchFor(this.artistAndSong)
              .then(response => {
                this.$emit('change', { searchTerm: `${this.artistAndSong}`, results: response })
              })
          }
        })
      } else if (this.artistAndSong) {
        DiscogsAPI.searchFor(this.artistAndSong)
        .then(response => {
          this.$emit('change', { searchTerm: `${this.artistAndSong}`, results: response })
        })
      }
    }
  }
})
