










import { DiscogsAPI } from '@/api'
import { listTrackInfo } from '@/helpers'
// eslint-disable-next-line no-unused-vars
import { DiscogsSearchResult, DiscogsSearchResults } from '@/types'
import Vue from 'vue'

function isADiscogsSearchResults(obj: any): obj is DiscogsSearchResults {
  if (obj.pagination && obj.results) return true
  return false
}

async function getAllRecords(records: DiscogsSearchResult[], currentPage: number, totalPages: number, nextUrl: string, depth: number): Promise<DiscogsSearchResult[]> {
  console.log('current depth', depth)

  const result = await DiscogsAPI
    .getResource(nextUrl)
    .then((r): { _records?: DiscogsSearchResult[], _currentPage?: number, _nextUrl?: string } | undefined => {
      if (isADiscogsSearchResults(r)) {
        if (r.results && r.results.length > 0) {
          console.log({
            _records: [...records, ...r.results],
            _currentPage: r.pagination?.page,
            _nextUrl: r.pagination?.urls.next
          })
          return {
            _records: [...records, ...r.results],
            _currentPage: r.pagination?.page,
            _nextUrl: r.pagination?.urls.next
          }
        }
      } else {
        return {}
      }
    })

    if (depth < 10 && result && result._records && result._currentPage) { 
      if (result._nextUrl) {
        return await getAllRecords(result._records, result._currentPage, totalPages, result._nextUrl, depth + 1)
      } else {
        return result._records
      }
    } else {
      return records
    }
}

export default Vue.extend({
  props: {
    results: {
      type: Object as () => DiscogsSearchResults,
      required: false,
      default: () => ({} as DiscogsSearchResults)
    },
    searchTerm: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      trackList: [] as Array<{ text: string; link: string; }>,
      progress: null as number | null,
      totalRecords: null as number | null,
      statusMessage: ''
    }
  },
  watch: {
    results: {
      deep: true,
      handler: async function(val: DiscogsSearchResults): Promise<void> {
        this.progress = null
        this.totalRecords = null
        this.statusMessage = ''
        this.trackList = [] as Array<{ text: string; link: string; }>

        if (val && val.results && val.results.length === 0) {
          this.statusMessage = 'No results found for ' + this.searchTerm + '. Search for another label.'
          return Promise.resolve()
        }

        if (val && val.results && val.results.length > 0) {
          let records = val.results

          const totalPages = val.pagination?.pages || 0

          const currentPage = val.pagination?.page || 0
          const nextUrl = val.pagination?.urls.next || ''
          if (currentPage < totalPages) {
            records = await getAllRecords(records, currentPage, totalPages, nextUrl, 1)
          }

          this.totalRecords = records.length

          const vm = this
          const progress = function(loadedUpTo: number) {
            vm.progress = loadedUpTo
          }

          if (this.searchTerm !== '' && localStorage[this.searchTerm.toLowerCase().trim()]) {
            this.trackList = JSON.parse(localStorage[this.searchTerm.toLowerCase().trim()])
            this.progress = records.length
            return Promise.resolve()
          }

          await listTrackInfo(records, DiscogsAPI, progress)
            .then(r => {
              this.trackList = r
              localStorage[this.searchTerm.toLowerCase().trim()] = JSON.stringify(r)
              return Promise.resolve()
            })
        }
      }
    },
    progress(progress) {
      if (progress && progress > 0 && this.totalRecords && this.totalRecords > 0) {
        const total = this.totalRecords
        if (progress === total) {
          this.statusMessage = total + ' for ' + this.searchTerm + ' complete.'
        } else {
          this.statusMessage = progress + ' out of ' + total + ' finished. ' + ((total - progress) * 2 / 60).toFixed(0) + ' minutes to go.'
        }
      } else {
        this.statusMessage = 'Search for a label.'
      }
    }
  },
  methods: {
    trackToHtml(trackText: string, trackUrl: string): string {
      const trackSplit = trackText.split(' - ')
      trackSplit[1] = `<a href="${trackUrl}" target="_blank">` + trackSplit[1]
      trackSplit[2] = trackSplit[2] + '</a>'
      return trackSplit.join(' - ')
    },
    downloadTextFile() {
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.trackList.map(t => t.text).join('\r\n')))
      element.setAttribute('download', this.searchTerm.toLowerCase().trim() + '.txt')

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    }
  }
})
