

























import Vue from "vue";
import { YoutubeAPI } from "@/api";
// eslint-disable-next-line no-unused-vars
import { YoutubeResponseItem } from "@/types";
import { getListOfYoutubeUrls, getListOfYoutubeUrlsExtraInfo } from "@/helpers";
import YoutubeSearchForm from "@/components/YoutubeSearchForm.vue";

export default Vue.extend({
  components: { YoutubeSearchForm },
  data() {
    return {
      searchTerm: "",
      results: [] as string[],
      resultsExtraInfo: [] as string[],
      status: "",
      showDownloadButton: false,
    };
  },
  watch: {
    searchTerm(newVal: string, oldVal: string): void {
      this.results = [];
      this.showDownloadButton = false;

      if (newVal && newVal !== oldVal) {
        this.status = "Searching for " + newVal;

        this.getYoutubeResults(newVal).then((response) => {
          if (response) {
            this.results = getListOfYoutubeUrls(response);
            this.resultsExtraInfo = getListOfYoutubeUrlsExtraInfo(response);
            this.prepareDownload();
            this.status = "";
          }
        });
      }
    },
  },
  methods: {
    prepareDownload(): void {
      this.showDownloadButton = true;
      this.$nextTick(() => {
        var downloadButton = document.getElementById("download");
        if (downloadButton) {
          downloadButton.focus();
        }
      });
    },
    async getYoutubeResults(
      searchTerm: string
    ): Promise<Array<YoutubeResponseItem>> {
      var results = [] as Array<YoutubeResponseItem>;
      var currentPage = 1;
      var nextPageToken = await YoutubeAPI.searchFor(searchTerm).then(
        (response) => {
          results.push(...response.items);
          return response.nextPageToken;
        }
      );

      while (nextPageToken && currentPage < 20 /* 1000 results */) {
        nextPageToken = await YoutubeAPI.searchFor(
          searchTerm,
          nextPageToken
        ).then((response) => {
          results.push(...response.items);
          return response.nextPageToken;
        });
        currentPage++;
      }

      return results;
    },
    downloadTextFile(): void {
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(
            this.results
              .map((t) =>
                t
                  .replace(/&amp;/g, "&")
                  .replace(/&#39;/g, "'")
                  .replace(/&quot;/g, '"')
              )
              .join("\r\n")
          )
      );
      element.setAttribute(
        "download",
        this.searchTerm.toLowerCase().trim() + ".txt"
      );

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    downloadTextFileWithExtraInfo(): void {
      const element = document.createElement("a");
      console.log(this.resultsExtraInfo);
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(
            this.resultsExtraInfo
              .map((t) =>
                t
                  .replace(/&amp;/g, "&")
                  .replace(/&#39;/g, "'")
                  .replace(/&quot;/g, '"')
              )
              .join("\r\n")
          )
      );
      element.setAttribute(
        "download",
        this.searchTerm.toLowerCase().trim() + " with titles.txt"
      );

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
});
