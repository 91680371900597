










import Vue from "vue";

// https://www.youtube.com/watch?v=Z3hfz-4QQP4&list=PLj7_vOzwIZY4m20PiTf5pCZyheOBhmSbx&index=5

export default Vue.extend({
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    handleSubmit() {
      const queryParams = this.searchTerm.split("?");
      console.log(queryParams);
      const params = new URLSearchParams(queryParams[1]);
      const playlistId = params.get("list");
      this.searchTerm = playlistId || "No playlist ID found";
      this.$emit("change", { searchTerm: this.searchTerm });
    },
    clearAllSearchTerms() {
      this.searchTerm = "";
      this.$emit("change", { searchTerm: this.searchTerm });
    },
  },
});
